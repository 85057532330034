import Header from '../components/Header';
import React, { useState } from 'react';
import { avList } from '../datas/avList';

import '../assets/styles/utils.css';
import './Contact.css';

function Contact() {
  const lastWorkId = window.localStorage.getItem('lastWorkId');
  const id = lastWorkId ? parseInt(lastWorkId.match(/\d+/)[0]) : 1;
  const data = avList[id-1];
  const [clicked, setClicked] = useState(false);
  const mail = 'alexandreroser@gmail.com';
  const dictionary = "0123456789qwertyuiopasdfghjklzxcvbnm!?></`~+*=@#$%".split('');

  const ran = () => {
    return Math.floor(Math.random() * dictionary.length)
  }

  const ranString = (amt) => {
    var string = '';
    for(var i = 0; i < amt; i++) {
      string += dictionary[ran()];
    }
    return string;
  }
  const randomString = ranString(mail.length);

  const decodeString = (e) => {
    if (!clicked) {
      e.preventDefault();
      var count = mail.length;
      var delay = 50;
      console.log('test');

      e.target.innerHTML = '';
    
      var gen = setInterval(() => {
        e.target.setAttribute('data-after', ranString(count));
        console.log(delay);
        console.log(count);
        if(delay > 0) {
          delay--;
        }
        else {
          if(count < mail.length) {
            e.target.innerHTML += mail[mail.length - count-1];
          }
          count--;
          if(count === -1) {
            clearInterval(gen);
          }
        }
      }, 32);
      e.target.href = `mailto:${mail}`;
      setClicked(true);
    }
  }

  return (
    <div className='contact-page'>
      <img className='background' src={ data.coverImg } alt=""/>
      <Header />
      <main>
        <ul>
          <li>
            <h3>Instagram</h3>
            <a href='https://www.instagram.com/ros_e_r/'>@ros_e_r</a>
          </li>
          <li>
            <h3>Facebook</h3>
            <a href='https://www.facebook.com/alfred.mootmoot'>alexandre roser</a>
          </li>
          <li>
            <h3>Adresse e-mail</h3>
            <a href='#' id='email' onClick={ decodeString } data-after={ randomString }></a>
          </li>
        </ul>
      </main>
    </div>
  );
}

export default Contact;
