import '../assets/styles/utils.css';
import './Header.css';

import { NavLink, useNavigate, Link } from 'react-router-dom'
import { useEffect } from 'react';

function Header() {
    const navigate = useNavigate();

    useEffect(() => {
        const el = document.getElementById("nav")
        const observer = new IntersectionObserver( 
        ([e]) => e.target.classList.toggle("sticky", e.intersectionRatio < 1),
            { threshold: [1] }
        );

        observer.observe(el);
    });

    return (
        <header id="nav">
            <h2><NavLink to="/" end>Roser</NavLink></h2>
            <span id='back-link' className='icon'>
                <Link
                    to={'..'}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                >
                    <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 4.5C21.2761 4.5 21.5 4.27614 21.5 4C21.5 3.72386 21.2761 3.5 21 3.5V4.5ZM0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM21 3.5L1 3.5V4.5L21 4.5V3.5Z" fill="black"/>
                    </svg>
                    Retour
                </Link>
            </span>
            <span id='contact-link'>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to="/contact">Contact</NavLink>
            </span>
        </header>
    );
}

export default Header;
