import React from 'react';
import { 
    Player,
    ControlBar,
    CurrentTimeDisplay,
    TimeDivider,
    VolumeMenuButton
 } from 'video-react';

import 'video-react/dist/video-react.css';
import '../assets/styles/utils.css';
import './Player.css';

function PlayerCustom({ data }) {
  return (
    <Player poster={data.coverImg}>
        <source src={data.url} />

        <ControlBar>
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <VolumeMenuButton />
        </ControlBar>
    </Player>
  );
}

export default PlayerCustom;
