import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/* import LoadingIcon from './LoadingIcon'; */

import '../assets/styles/utils.css';
import './Carousel.css';

function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.left >= 0 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

function Carousel(props) {
    const [startIndex, setStartIndex] = useState(2);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [thumbPosition, setthumbPosition] = useState(0);
    const nbSlide = props.data.length;
    const items = document.getElementsByClassName('carousel-slide');
    var waitForSwipe = null;

    const handleIndex = (index, id) => {
        const resetIndex = (index+1 == nbSlide) ? 1 : index+2
        setStartIndex(resetIndex);
        setCurrentIndex(index+1);
        window.localStorage.setItem('lastWorkId', id);
    }

    const handleScroll = (e) => {
        setthumbPosition(`${(e.target.scrollLeft * 100) / e.target.scrollWidth}%`);
        /* if(window.matchMedia("(pointer: coarse)").matches) {
        } */
    }

    const handleTouchStart = () => {
        if(waitForSwipe !== null) {
            clearTimeout(waitForSwipe);        
        }
        waitForSwipe = setTimeout(function() {
              for (let el of items) {
                if (isElementInViewport(el)) {
                    const extractIndex = parseInt(el.dataset.index);
                    const resetIndex = (extractIndex+1 == nbSlide) ? 1 : extractIndex+2
                    setStartIndex(resetIndex);
                    setCurrentIndex(extractIndex+1);
                    window.localStorage.setItem('lastWorkId', el.dataset.workId);
                }
            };
        }, 1000);
    }
    
    return (
        <div className='carousel-wrapper'>
            <div className='carousel' onScroll={handleScroll} onTouchStart={handleTouchStart}>
                {
                    props.data.map((elem, index) => (
                        <div key={elem.id} id={`slide${index}`} data-work-id={elem.id} data-index={index} className='carousel-slide'>
                            <video autoPlay muted playsInline loop src={elem.teaser} />
                            <Link className='text-wrapper' to={ `/work/${elem.id}` }>
                                <h2 className='title'>{ elem.name }</h2>
                                <span className='subtitle'>{ elem.subtitle }</span>
                            </Link>
                        </div>
                    ))
                }
            </div>
            <div className='navigation'>
                <p className='tracklist'>
                    <span id='currentIndex'>{currentIndex}</span><span> - {props.data.length}</span>
                </p>
                {
                    props.data.map((elem, index) => (
                        <a key={`anchor${elem.id}`} href={`#slide${index}`} onClick={() => handleIndex(index, elem.id)}>
                            {`Slide ${index}`}
                        </a>
                    ))
                }
                <div className='fake-scrollbar'><div className='thumb' style={{ width: `calc(100% / ${nbSlide})`, left: thumbPosition }}></div></div>
            </div>
    </div>
    );
}

export default Carousel;
