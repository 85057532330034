import cover from '../assets/images/jnpcda_cover.png';
import cover2 from '../assets/images/bmosvp_cover.png';
import cover3 from '../assets/images/lspt_cover.png';

import teaser from '../assets/clip/JNPCD_16-9.mp4';
import teaser2 from '../assets/clip/video10s_dogme23.mp4';
import teaser3 from '../assets/clip/video10s_LPSTromanphoto.mp4';

import video1 from '../assets/clip/JNPCD_16-9.mp4';
import video2 from '../assets/video/BMOSVP-VOSTEN.mp4';
import video3 from '../assets/video/LSPT_V1_WIP.mp4';

export const avList = [
    {
        id: 'video1',
        name: 'Je n\'ai pas changé d\'adresse',
        subtitle: 'Court-métrage',
        descr: 'Réalisateur',
        url: video1,
        downloadLink: '',
        coverImg: cover, 
        teaser: teaser
    },
    {
        id: 'video2',
        name: 'Bonjour merci oui, s\'il vous plaît.',
        subtitle: 'Court-métrage',
        descr: 'Co-scénariste, assistant chef-opérateur.',
        url: video2,
        downloadLink: '',
        coverImg: cover2,
        teaser: teaser2
    },
    {
        id: 'video3',
        name: 'Le Second Principe de la Thermodynamique',
        subtitle: 'Court-métrage',
        descr: 'Co-directeur-artistique, chef-opérateur.',
        url: video3,
        downloadLink: '',
        teaser: teaser3,
        coverImg: cover3
    }
  ];
  