import '../assets/styles/utils.css';
import './App.css';

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';

import Home from './Home';
import Details from './Details';
import Contact from './Contact';
import Error from '../components/Error/Error';

const usePrevious = (value) => {
  const ref = React.useRef()
  React.useEffect(() => { ref.current = value })

  return ref.current
}

const useLocationChange = (action) => {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  React.useEffect(() => { 
    action(location, prevLocation) 
  }, [location])
}

function App() {
  const location = useLocation();

  useLocationChange((location, prevLocation) => { 
    console.log('changed from', prevLocation, 'to', location) 
  })

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work/:workId" element={<Details />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
    </div>
  );
}

const Root = () => <BrowserRouter><App /></BrowserRouter>;

export default Root;
