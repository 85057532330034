import '../assets/styles/utils.css';

import { avList } from '../datas/avList';
import Header from '../components/Header';
import Carousel from '../components/Carousel';

function Home() {
  return (
    <div className='homepage'>
      <Header />
      <main>
        <section id="work">
            <Carousel data={ avList } />
        </section>
      </main>
    </div>
  );
}

export default Home;
