import '../../assets/styles/utils.css';
import '../../assets/styles/Error.css';

function Error() {
  return (
    <section className='error uppercase'>
        404
    </section>
  );
}

export default Error;
