import React from 'react';

import '../assets/styles/utils.css';
import './Gallery.css';

function Gallery({ data }) {
  return (
    <section className='gallery'>
      {
        data.map((elem, index) => (
          <img src={ elem } className='gallery-img' alt=""/>
        ))
      }
    </section>
  );
}

export default Gallery;
