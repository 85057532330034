import { avList } from '../datas/avList';
import React, { useEffect } from 'react';
import Header from '../components/Header';
import PlayerCustom from '../components/Player';
import Gallery from '../components/Gallery';
import { useParams } from "react-router-dom";

import '../assets/styles/utils.css';
import './Details.css';

function Details() {
  const params = useParams();
  const id = parseInt(params.workId.match(/\d+/)[0]);
  const data = avList[id-1];

  useEffect(() => {
    window.localStorage.setItem('lastWorkId', params.workId);
  }, [id]);

  return (
    <div className='details-page'>
      <Header />
        <main>
          <PlayerCustom data={ data }/>
          <section className='text-wrapper'>
            <h1 className='title'>{ data.name }</h1>
            <p className='subtitle'>{ data.subtitle }</p>
            <p className='descr'>{ data.descr }</p>
          </section>
          {
            data.gallery && <Gallery data={ data.gallery }/>
          }
        </main>
    </div>
  );
}

export default Details;
